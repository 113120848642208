import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DMNLogoAligned from "./DMNLogoAligned";
import ArtLinkLogo from "../assets/artlinktext.png";
import { faGear } from "@fortawesome/free-solid-svg-icons";

interface TopBarProps {
  triggerModal: () => void;
  topBarRef: React.RefObject<HTMLDivElement>;
}

const TopBar = ({ triggerModal, topBarRef }: TopBarProps) => {
  return (
    <div
      ref={topBarRef}
      className="no-outline"
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "10px",
        marginTop: "20px",
      }}
    >
      {/* Logo */}
      <div
        style={{
          display: "flex",
          alignItems: "flex-end", // Aligns text with the bottom of the logo
        }}
      >
        <DMNLogoAligned />
        <img
          src={ArtLinkLogo}
          alt="logo"
          style={{
            marginLeft: "10px",
            marginBottom: "7px",
            width: '111px',
            height: '25px'
          }}
        />
      </div>
      <FontAwesomeIcon
        onClick={triggerModal}
        icon={faGear}
        size="2xl"
        aria-label="Settings"
        style={{ cursor: "pointer" }} // Ensures pointer cursor on icon
      />
    </div>
  );
};

export default TopBar;
